import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { useInView } from "react-intersection-observer";

import "./App.css";
import "./plugins.css";
import "./base.css";

const Header = React.lazy(() => import("./Components/Header/Index"));
const Banner = React.lazy(() => import("./Components/Banner/Index"));
const Video = React.lazy(() => import("./Components/Video"));
const Counter = React.lazy(() => import("./Components/Counter"));
const Restaurant = React.lazy(() => import("./Components/Restaurant"));
const Casestudy = React.lazy(() => import("./Components/Casestudy"));
const VideoReview = React.lazy(() => import("./Components/VideoReview"));
const Review = React.lazy(() => import("./Components/Review"));
const OurWorks = React.lazy(() => import("./Components/OurWorks"));
const WinningMethod = React.lazy(() => import("./Components/WinningMethod"));
const System = React.lazy(() => import("./Components/System"));
const Demo = React.lazy(() => import("./Components/Demo"));
const Footer = React.lazy(() => import("./Components/Footer"));

const LazyLoadComponent = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return <div ref={ref}>{inView ? children : null}</div>;
};

const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Track overall loading state

  useEffect(() => {
    const apiUrl =
      "https://webapps.eqserver.net/winmmedia-co-uk/wp-json/wp/v2/pages/189";

    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data.data);
        setLoading(false); // Data has been loaded
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Even if there's an error, stop loading state
      });
  }, []);

  // Fallback UI while components are loading
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {data &&
        data.map((sectionData, index) => (
          <div key={index}>
            {Object.keys(sectionData).map((sectionKey, i) => {
              switch (sectionKey) {
                case "header_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Header...</div>}>
                        <Header data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "banner_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Banner...</div>}>
                        <Banner data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "video_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Video...</div>}>
                        <Video data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "counter_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Counter...</div>}>
                        <Counter data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "logo_slider_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Restaurant...</div>}>
                        <Restaurant data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "case_study_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Case Study...</div>}>
                        <Casestudy data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "client_review_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Video Review...</div>}>
                        <VideoReview data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "google_review_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Review...</div>}>
                        <Review data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "our_works_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Our Works...</div>}>
                        <OurWorks data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "winning_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Winning Method...</div>}>
                        <WinningMethod data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "core_areas_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading System...</div>}>
                        <System data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "demo_form_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Demo...</div>}>
                        <Demo data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                case "footer_section":
                  return (
                    <LazyLoadComponent key={i}>
                      <Suspense fallback={<div>Loading Footer...</div>}>
                        <Footer data={sectionData[sectionKey]} />
                      </Suspense>
                    </LazyLoadComponent>
                  );
                default:
                  return null;
              }
            })}
          </div>
        ))}
    </>
  );
};

export default App;
